import { ErrorCodes } from '@general/error-codes';
import { useFormik } from 'formik';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { sendFunds } from 'api/payment';
import { AccountName, TwoFaModal, SwitchBlock } from 'components';
import { GlobalLoaderContext } from 'contexts/global-loader';
import TwoFaProvider from 'contexts/two-fa';
import { useInterval } from 'hooks';
import {
  CONFIRMATION_FORM,
  FROM_ACCOUNT,
  PAYMENT_REFERENCE,
  POLL_TIMEOUT,
  PURPOSE_CODE,
  SEND_AMOUNT,
  SEND_FUNDS_AMOUNT_FORM,
  SEND_FUNDS_FINAL,
} from 'pages/send-funds/constants';
import createAmountValidationSchema from 'pages/send-funds/schemas/amount';
import {
  getBeneficiaryName,
  getConfirmationFromBoxContent,
  getConfirmationPaymentReferenceContent,
  getTotal,
  shouldRedirectToAmountError,
  updateRate,
  updateSelectedAccountIfRequired,
  validateAmountFormValues,
} from 'pages/send-funds/utils';
import { isFxOutgoing, prepareTransaction } from 'pages/send-funds/utils/fx';
import { errorsConsistOneOfErrorCodes, rethrowTwoFaError } from 'utils/errors';
import { getBeneficiaries } from 'api/beneficiary';

import S from './styles';

function SendFundsConfirmation({
  setCurrentView,
  beneficiaryFormValues,
  amountFormValues,
  setAmountFormValues,
  setCreatedTransaction,
  updateAccounts,
  setSubmissionErrors,
}) {
  const { startLoading, endLoading } = useContext(GlobalLoaderContext);
  const [ twoFaModalIsOpen, setTwoFaModalIsOpen ] = useState(false);
  const [ twoFaData, setTwoFaData ] = useState(null);

  const [ showTrustBeneficiaryToggle, setShowTrustBeneficiaryToggle ] = useState(false);
  const [ isTrusted, setIsTrusted ] = useState(false);

  const onSubmit = () => {
    setAmountFormValues({
      ...amountFormValues,
      [PAYMENT_REFERENCE]: values[PAYMENT_REFERENCE],
    });

    sendFundsRequest();
  };

  const formik = useFormik({
    initialValues: {
      ...beneficiaryFormValues,
      ...amountFormValues,
    },
    onSubmit,
    enableReinitialize: true,
    validationSchema: createAmountValidationSchema,
  });

  const { handleSubmit, setFieldValue, setFieldTouched, values } = formik;

  useEffect(async () => {
    const transaction = prepareTransaction(beneficiaryFormValues, amountFormValues);
    const accountNumber = get(transaction, 'beneficiary.account.number.value');

    const {
      numberOfRecords: numberOfNonTrustedBeneficiaries,
    } = await getBeneficiaries({ accountNumbers: [accountNumber], isTrusted: false, limit: 1 });

    setShowTrustBeneficiaryToggle(numberOfNonTrustedBeneficiaries > 0);
  }, [beneficiaryFormValues]);

  useEffect(() => {
    validateAmountFormValues(amountFormValues, () => setCurrentView(SEND_FUNDS_AMOUNT_FORM));
    setFieldValue(PAYMENT_REFERENCE, amountFormValues[PAYMENT_REFERENCE]);
    setFieldTouched(PAYMENT_REFERENCE);
  }, [amountFormValues]);

  const handleResendCode = async (factor, scaId) => {
    const transaction = prepareTransaction(beneficiaryFormValues, amountFormValues);
    const payload = { challenge: { preferredFactor: factor, scaId }, ...transaction };

    const data = await sendFunds(payload);
    setTwoFaData(data?.challenge);

    return data;
  };

  useInterval(() => {
    const handleAccounts = async () => {
      const updatedAccounts = await updateAccounts();
      updateSelectedAccountIfRequired(amountFormValues, updatedAccounts, (fieldName, value) =>
        setAmountFormValues({
          ...amountFormValues,
          [fieldName]: value,
        }));
      if (isFxOutgoing(beneficiaryFormValues, amountFormValues)) {
        updateRate(beneficiaryFormValues, amountFormValues, setAmountFormValues);
      }
    };

    handleAccounts();
  }, POLL_TIMEOUT);

  const handleSwitchChange = () => {
    setIsTrusted(!isTrusted);
  };

  const handleConfirmCode = async challenge => {
    startLoading();

    try {
      const transaction = prepareTransaction(beneficiaryFormValues, amountFormValues);
      const data = await sendFunds({ ...transaction, challenge, isTrusted });

      setCreatedTransaction(data);
      setCurrentView(SEND_FUNDS_FINAL);
    } catch (error) {
      rethrowTwoFaError(error);

      setCurrentView(SEND_FUNDS_FINAL);
    } finally {
      endLoading();
    }
  };

  const sendFundsRequest = async () => {
    startLoading();

    try {
      const transaction = prepareTransaction(beneficiaryFormValues, amountFormValues);
      const data = await sendFunds({ ...transaction, isTrusted });

      if (data.challenge) {
        setTwoFaData(data.challenge);
        setTwoFaModalIsOpen(true);

        return;
      }

      setCreatedTransaction(data);
      setCurrentView(SEND_FUNDS_FINAL);
    } catch (error) {
      rethrowTwoFaError(error);

      setSubmissionErrors(error.data);

      if (errorsConsistOneOfErrorCodes(error.data, [ ErrorCodes.messageBlocked, ErrorCodes.regionBlocked ])) {
        toast.error(error.data.default?.message);

        return;
      }

      if (shouldRedirectToAmountError(error.data)) {
        setCurrentView(SEND_FUNDS_AMOUNT_FORM);

        return;
      }
      setCurrentView(SEND_FUNDS_FINAL);
    } finally {
      endLoading();
    }
  };

  const amountWithCurrency = () => {
    const currency = get(amountFormValues, `${FROM_ACCOUNT}.value.currency`);
    const amount = get(amountFormValues, SEND_AMOUNT);

    return `${amount} ${currency}`;
  };

  const { name: beneficiary } = getBeneficiaryName(beneficiaryFormValues);

  return (
    <>
      <form id={CONFIRMATION_FORM} name={CONFIRMATION_FORM} onSubmit={handleSubmit}>
        <S.Container>
          <S.Title>Review and confirm</S.Title>
          <S.AmountAndAccountContainer>
            <div>
              {amountWithCurrency()}
            </div>
            <div>
              <S.ArrowRight />
            </div>
            <S.BeneficiaryName>
              {beneficiary}
            </S.BeneficiaryName>
          </S.AmountAndAccountContainer>
          <S.TotalContainer
            content={getConfirmationFromBoxContent(amountFormValues[FROM_ACCOUNT], beneficiaryFormValues, AccountName)}
          />
          <S.TotalContainer
            content={getConfirmationPaymentReferenceContent(amountFormValues[PURPOSE_CODE],
              amountFormValues[PAYMENT_REFERENCE])}
          />
          <S.TotalContainer content={getTotal(beneficiaryFormValues, amountFormValues, true)} />
          {showTrustBeneficiaryToggle && (
            <S.SwitchBlockContainer>
              <SwitchBlock
                title="Trust this beneficiary"
                titleIcon={<S.TrustedIcon />}
                text="We won’t ask for authentication for future payments to this account"
                checked={isTrusted}
                onChange={handleSwitchChange}
              />
            </S.SwitchBlockContainer>
          )}
          <S.Button label="Send funds" category="primary" type="submit" />
          <S.CancelButton />
        </S.Container>
      </form>
      {twoFaModalIsOpen && (
        <TwoFaProvider twoFaData={twoFaData}>
          <TwoFaModal
            isOpen={twoFaModalIsOpen}
            setIsOpen={setTwoFaModalIsOpen}
            onSubmit={handleConfirmCode}
            onResend={handleResendCode}
            challenge={twoFaData}
          />
        </TwoFaProvider>
      )}
    </>
  );
}

SendFundsConfirmation.propTypes = {
  setCurrentView: PropTypes.func.isRequired,
  beneficiaryFormValues: PropTypes.instanceOf(Object).isRequired,
  amountFormValues: PropTypes.instanceOf(Object).isRequired,
  setAmountFormValues: PropTypes.func.isRequired,
  setCreatedTransaction: PropTypes.func.isRequired,
  updateAccounts: PropTypes.func.isRequired,
  setSubmissionErrors: PropTypes.func.isRequired,
};

export default SendFundsConfirmation;
